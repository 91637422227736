.Studies-text-area {
    background-color: white;
    color:rgb(0, 0, 0);
    font-family: sans-serif;
    width: 85%;
    height: 5vh;
    box-sizing: border-box;
    border-radius: 4px;
    resize: none;
    border: none;
    font-size: calc(4px + 2vmin);
    margin: 10px;
    padding: 10px;
}

.Studies-text-area:focus {
    outline: none !important; 
}