.List-options {
    background-color: #e9e0d1;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2vh;
    border-radius: 7px;
}

.List-options-buttons {
    background-color: transparent;
    font-size: calc(2px + 2vmin);
    padding: 5px;
    margin: 10px;
    border: none;
    color: #383938;
}

.List-selected-options-buttons {
    background-color: transparent;
    font-size: calc(2px + 2vmin);
    padding: 5px;
    margin: 10px;
    border: none;
    color: black;
}

.Bookmarks {
    width: 94%;
    background-color: #e9e0d1;
    margin-bottom: 1vh;
    border-radius: 6px;
    padding: 10px;
}

.Bookmarks-list-background {
    width: 94%;
    background-color: #e9e0d1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.Bookmarks-button-row {
    background-color:  transparent;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-color: transparent;
    padding: 5px;
}

.Bookmarks-reference-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Bookmarks-reference {
    background-color: transparent;
    font-size: calc(8px + 2vmin);
    border-radius: 5px;
    color: Black;
}

.Bookmarks-edit-button{
    background-color:  #383938;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: Black;
    border: none;
    border-radius: 100%;
    cursor: pointer
}

.Bookmarks-edit-icon {
    height: 18px;
    width: 18px;
}