.NewVersefromHome { 
    background-color: transparent;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; 
    border-radius: 8px;
}

.NewVerse-reference-row {
    background-color: transparent;
    width: 92%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: black;
    margin: 2%;
}

.NewVersefromHome-reference {
    background-color: White;
    width: 100%;
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: black;
    border-radius: 5px;
    padding: 2%;
    overflow-y: auto;
}

.NewVerse-bookmark-and-switch {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
}

.NewVerse-SaveVerse-button{
    height: 60px;
    width: 60px;
    background-color: transparent;
    color:  #383938;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: none;
    border-radius: 100%;
    padding: 5px;
    font-size: calc(2px + 2vmin);
    cursor: pointer
}

.NewVerse-SaveVerse-button:hover {
    background-color:  #f4efe5;
}

.NewVerse-bookmark-icon{
    height: 34px;
    width: 25px;
}

.NewVersefromHome-reference-buttons {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer
}

.NewVersefromHome-reference-buttons:hover {
    background-color: rgb(230, 230, 230);
}

.NewVerse-selector-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; 
}

.ListfromHome {
    width: 96%;
    height: 20vh; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; 
    background-color: White;
    color: #383938;
    /* border: 2px solid #cec5b3; */
    margin: 2%;
    margin-top: 0%;
    border-radius: 5px;
    font-weight: normal;
    overflow-y: scroll;
}