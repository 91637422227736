.NewStudy {
    width: 94%;
    background-color:  #e9e0d1;
    margin-bottom: 1vh;
    border-radius: 6px;
    padding: 10px;
}

.NewStudy-edit-button{
    background-color:  #383938;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: Black;
    border: none;
    border-radius: 100%;
    cursor: pointer
}

.NewStudy-edit-icon {
    height: 18px;
    width: 18px;
}

.NewStudy-edit-button:hover {
    border-radius: 100%;
    background-color:  #505150;
}

.NewStudy-reference-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.NewStudy-reference {
    background-color: transparent;
    font-size: calc(8px + 2vmin);
    border-radius: 5px;
    color: Black;
}

.NewStudy-button-row {
    background-color:  transparent;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-color: transparent;
    padding: 5px;
}

.NewStudy-input-row {
    background-color:  transparent;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-color: transparent;
    margin-top: 10px;
}

.NewStudy-Add-Button {
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #383938;
    font-size: calc(14px + 2vmin);
    border-radius: 100%;
    color: white;
    border: none;
    margin: 5px;
    margin-left: 0px;
}

.NewStudy-Add-Button:hover {
    border-radius: 100%;
    background-color:  #505150;
}
