.ChangeTranslation-row {
    background-color: White;
    width: 30%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; 
    margin: 1%;
    border-radius: 5px;
    padding: 3px;
}

.ChangeTranslation-buttons{
    background-color: transparent;
    width: 50%; 
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    color: #817c76;
    border: none;
}

.ChangeTranslation-buttons:hover {
    background-color: White;
    color: #383938;
    border-radius: 5px;
}

.ChangeTranslation-buttons-selected{
    background-color:  #e9e0d1;
    width: 50%; 
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    color: #383938;
    border: none;
    border-radius: 3px;
}