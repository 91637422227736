.Challenges-page-container {
    width: 100%;
    height: 80vh;
    margin: 4vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border-radius: 6px;
}

.Challenges {
    background-color:  transparent;
    width: 100%;
    margin-bottom: 1vh;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Challenges-button-row {
    background-color: #e9e0d1;
    width: 94%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-color: transparent;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 1vh;
}

.Challenges-reference-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.Challenges-reference {
    height: 35px;
    background-color: transparent;
    font-size: calc(8px + 2vmin);
    padding: 5px;
    border-radius: 5px;
    color: Black;
}

.Challenges-list-background {
    background-color: #e9e0d1;
    width: 94%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 6px;
    overflow-y: hidden;
}

.Challenges-list-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Challenges-name-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Challenges-name-container {
    background-color:  transparent;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 6px;
    font-size: calc(4px + 2vmin);
    border: none;
    color: #383938;
    cursor: pointer;
}

.Challenges-description-container {
    background-color:  transparent;
    border-radius: 6px;
    width: 95%;
    text-align: left;
    border-radius: 6px;
    font-size: calc(8px + 1vmin);
    border: none;
    color: black;
    padding-top: 10px;
}

.Challenges-passages-container {
    background-color: white;
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    border-radius: 5px;
    margin-top: 15px;
    padding: 5px;
    margin-bottom: 10px;
}

.Challenges-passage-button-container {
    background-color: transparent;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: transparent;
}

.Challenges-passage-name-container {
    width: 100%;
    text-align: left;
    font-size: calc(4px + 2vmin);
    color: #383938;
    padding: 5px;
}