.Navbar {
    background-color:  #e9e0d1;
    height: 8vh;  
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between; 
    padding-bottom: 15px;
}

.Hamburger {
    background-color:  #e9e0d1;
    width: 45vh;
    max-height: 91vh;
    position: absolute;
    z-index: 1;
    right: 0;
    box-shadow: 0px 10px 14px -8px #121110;
    padding-bottom: 2%;
    border-bottom-left-radius: 5px;
}

.logo-container {
    cursor: pointer;
    margin-left: 3%; 
    color: #2f302f;
    font-size: calc(20px + 2vmin);
    font-weight: 400;
}

.logo {
    height: 40px;
}

.hamburger-container {
    width: 60px;
    height: 60px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;  
    margin-right: 5%;
    border: none;
    border-radius: 100%;
}

.hamburger-container:hover {
    background-color:  #f4efe5;
}

.hamburger-menu-icon {
    height: 30px;
    width: 40px;
}

.hamburger-links-container {
    width: 45vh;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center; 
    border: none;
}

.hamburger-link {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 5px;
    padding: 10px; 
    align-items: center;
    margin-right: 20px; 
    font-size: calc(8px + 2vmin);
    text-decoration: none;
}

.bookmarks-list {
    background-color: transparent;
    width: 90%;
    max-height: 56vh;
    overflow-y: auto;
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-top: 2px solid #383938;
    border-bottom: 2px solid #383938;
}

.links-container {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 1%;
    border: none;
}

.link {
    background-color: transparent;
    border:none;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 5px;
    padding: 5px; 
    align-items: center;
    margin-right: 20px; 
    font-size: calc(8px + 2vmin);
    text-decoration: none;
}

.link-button {
    background-color: #383938;
    color: white;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 5px;
    padding: 10px; 
    align-items: center;
    margin: 10px;
    margin-right: 30px; 
    margin-left: 0px;
    font-size: calc(1px + 2vmin);
    text-decoration: none;
    border: none;
}

.link-button:hover {
    background-color: #586458;
}

.link:hover {
    border-radius: 0px;
}

.link-text {
    color: #383938;
    text-decoration: none;
}

.link-text:hover {
    color: #5b5d5b;
}

.link-user-text {
    color: #5b5d5b;
    text-decoration: none;
    padding: 5px;
}

.link-user-text:hover {
    color: #383938;
    text-decoration: none;
}

.link-button-text {
    color: white;
    text-decoration: none;
}