.App {
  text-align: center;
  align-items: center;
}

.App-background {
  background-color: transparent;
  height: 91vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.brightness { background: rgba(0,0,0,0.50) }