.Home {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 4vh;
  }

  .Home-currentVerse {
    background-color: transparent;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-right: 2vh;
    margin-left: 2vh;
  }

  .Home-studies-list {
    width: 55vw;
    height: 80vh;
    overflow-y: auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2vh;
  }

  .Home-bookmarks-list {
    width: 45vw;
    height: 80vh;
    overflow-y: auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2vh;
  }
  
  .Home-textFieldBackground {
    background-color:  #e9e0d1;
    max-width: 100%;
    width: 100%;
    height: fit-content;
    min-height: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-top: 1vh;
    border-radius: 8px;
  }

  .Home-referenceBackground {
    background-color:  #e9e0d1;
    max-width: 100%;
    width: 100%; 
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    border-radius: 8px; 
    margin-bottom: 1vh;
  }
  
  .Home-reference {
    background-color: White;
    width: 92%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: black;
    padding: 2%;
    border-radius: 5px;
    margin: 2%
  }

  .Home-textField {
    background-color: White;
    width: 96%;
    height: 100vh;
    max-height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(6px + 2vmin);
    line-height: 36px;
    text-align: left;
    color: black;
    padding: 2%;
    border-radius: 5px;
    margin: 2%;
    margin-top: 1%;
    overflow-y: auto;
    border: none;
    position: relative;
  }

  .Home-textField:focus {
    outline: none;
  }

  .Home-mode-row {
    width: 50vh;
    height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #383938;
    margin: 1%;
    margin-left: 4%;
    margin-bottom: 0%;
  }

  .Home-switch-text {
    font-size: calc(4px + 2vmin);
    margin-right: 20px;
  }
  
  .Home-operationButtonsRow {
    width: 92%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; 
    padding: 2%;
    padding-top: 0%;
    margin-left: 2%;
  }
  
  .Home-operationsButtonStyle {
    background-color: #383938;
    width: 90px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 2vmin);
    color: white;
    border-radius: 3px;
    border: none;
    cursor: pointer
  }

  .Home-operations-icons {
    height: 18px;
  }
  
  .Home-operationsButtonStyle:hover {
    background-color: #494949;
  }

  .Home-arrow-keys-container {
    background-color: #dedede;
    width: 96%;
    height: 90%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  .Home-arrow-keys-icon {
    width: 25%;
  }

  .Home-arrow-keys-text {
    font-size: calc(2px + 2vmin);
  }

  .Submit-operationsButtonStyle {
    background-color:  #e9e0d1;
    text-align: center;
    font-size: calc(6px + 2vmin);
    margin: 2%;
    border: none;
    color: #383938;
    margin-right: 5%;
    padding: 15px;
    border-radius: 50px;
  }
  
  .Submit-operationsButtonStyle:hover {
    background-color:  #f4efe5;
  }
  
  .Submit-operationButtonsRow {
    width: 100%; 
    max-width: 100%; 
    max-height: 10%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left; 
    font-size: calc(4px + 2vmin);
  }
  