.CheckVerseTypeTwo {
    text-align: center;
    align-items: center;
}

.Type2-hint-row {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: #383938;
    font-size: calc(2px + 2vmin);
}

.Type2-hint-button{
    background-color: transparent;
    color: #383938;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: calc(6px + 1vmin);
    border-radius: 100%;
    margin-left: 10px;
}

.Type2-hint-button:hover{
    background-color:  #f4efe5;
}


.Type2-hint-button-icon{
    width: 40px;
    height: 40px;
    /* border: 1.5px solid #383938; */
    padding: 5px;
}

.Type2-textField-shape {
    background-color: White;
    width: 100%;
    max-width: 92%;
    height: 100vh;
    max-height: 100%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(6px + 2vmin);
    line-height: 36px;
    text-align: left;
    color: black;
    padding: 2%;
    border-radius: 5px;
    margin: 2%;
    overflow-y: auto;
    margin-top: 1%;
}

.Type2-textField-display {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    font-size: calc(6px + 2vmin);
    text-align: left;
    color: black;
}

.Type2-textField {
    background-color: transparent;
    width: fit-content;
    min-width: 10px;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: calc(6px + 2vmin);
    text-align: left;
    color: black;
    border:none;
    overflow: hidden;
    padding-right: 1%;
}

.Type2-textField-input {
    background-color: #e9e0d1;
    min-width: 10px;
    height: 35px;
    color: white;
    border:none;
    border-radius: 4px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.Type2-Status-Change {
    width: 92%;
    height: 45px;
    color: #383938;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2%;
    padding-top: 0%;
    margin-left: 2%;
}

.Type2-Status-Change-Text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Type2-refresh-button{
    background-color: transparent;
    color: #383938;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    font-size: calc(6px + 1vmin);
    margin-left: 1%;
    border-radius: 100%;
}

.Type2-refresh-button:hover{
    background-color:  #f4efe5;
}

.Type2-refresh-button-icon{
    width: 34px;
    height: 30px;
}