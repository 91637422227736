.BookmarksAccordion-background {
    width: inherit;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
}

.BookmarksAccordian-notSignedIn {
    text-align: center;
    color: #2f302f;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: calc(4px + 2vmin);
    font-weight: 550;
    border-radius: 5px;
    margin: 10px;
    text-decoration: none;
}

.BookmarksAccordian-notSignedIn-text {
    text-align: center;
    color: #2f302f;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: calc(4px + 2vmin);
    font-weight: 550;
    border-radius: 5px;
    text-decoration: none;
}

.BookmarksAccordion-selected-button{
    background-color:  transparent;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: Black;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: transparent;
    padding: 5px;
    border-radius: 4px;
}

.BookmarksAccordion-selected-button:hover {
    background-color: rgb(230, 230, 230);
}

.BookmarksAccordion-selected-icon {
    height: 10px;
    width: 10px;
    padding-right: 1%;
}

.BookmarksAccordion-reference-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.BookmarksAccordion-reference {
    background-color: transparent;
    font-size: calc(4px + 2vmin);
    padding: 1%;
    border-radius: 5px;
    color: #565756;
}

.BookmarksAccordion-selected-reference {
    background-color: transparent;
    font-size: calc(4px + 2vmin);
    padding: 1%;
    border-radius: 5px;
    color: black;
}

.BookmarksAccordion-button-row {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-color: transparent;
}

.BookmarksAccordion-remove-button {
    height: 20px;
    width: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    padding: 4%;
    margin:1%;
    cursor: pointer
}

.BookmarksAccordion-remove-icon {
    height: 20px;
    width: 20px;
    border-radius: 100%;
}

.BookmarksAccordion-remove-button:hover {
    background-color:  #ec988b;
}
