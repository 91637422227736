.Text-area {
    background-color: transparent;
    color:rgb(0, 0, 0);
    font-family: sans-serif;
    width: inherit;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    resize: none;
    border: none;
    font-size: calc(4px + 2vmin);
    overflow: hidden;
    padding-top: 5px;
}

.Text-area:focus {
    outline: none !important; 
}