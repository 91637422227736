.Book-button-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    color: #383938;
    margin: 0 auto;
    overflow: auto;
}

.Book-buttons {
    width: 95%; 
    height: 30px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-size: calc(4px + 1vmin);
    border-radius: 50px;
    color: #817c76;
    border: none;
    padding-left: 15px;
    margin: 0.5%;
}

.Book-name {
    padding: 5px;
}

.Book-buttons:hover {
    background-color: rgb(230, 230, 230);
    color: #383938;
    border-radius: 5px;
}

.horizontal-line {
    width: 100%;
    border: 1px solid  #e8e5df;
}

.Book-search-row {
    width: 100%; 
    margin: 0 auto;
    overflow: auto;
}

.Chapter-button-row {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    margin: 0 auto;
    overflow: auto;
    border-radius: 5px;
}

.Chapter-buttons {
    width: 100%; 
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 1vmin);
    color: #817c76;
    border:none;
}

.Chapter-buttons-selected {
    width: 100%; 
    background-color: white;
    color: #383938;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 1vmin);
    border:none;
}

.Chapter-button-text {
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #817c76;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.Chapter-button-text:hover {
    width: 50px;
    height: 50px;
    background-color: rgb(230, 230, 230);
    color: #383938;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.Verse-button-row {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    color: #383938;
    margin: 0 auto;
    overflow: auto;
    border-radius: 5px;
}
.Verse-buttons {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 1vmin);
    color: #817c76;
    border:none;
}

.Verse-buttons:hover {
    color: #383938;
}

.Verse-buttons-selected {
    background-color: transparent;
    color: #383938;
    width: 100%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 1vmin);
    border:none;
}

.Verse-button-text {
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #817c76;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.Verse-button-text:hover {
    width: 50px;
    height: 50px;
    background-color: rgb(230, 230, 230);
    color: #383938;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.Verse-button-text-selected {
    width: 50px;
    height: 50px;
    background-color: rgb(230, 230, 230);
    color: #383938;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.Verse-button-text-range {
    width: 45px;
    height: 45px;
    background-color: rgb(230, 230, 230);
    color: #817c76;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.Search-bar {
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top-left-radius:10px;
    border-top-right-radius: 10px;
    border: none;
    border-bottom:  2px solid #e8e5df;
    padding: 3px;
    margin-left: 10px;
    margin-right: 10px;
}

.Search-icon {
    width: 15px;
    padding: 5px;
    padding-right: 0px;
    margin: 5px;
    margin-left: 10px;
}

.Input-search-bar {
    color: #817c76;
    width: 150px;
    height: 1vh;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: calc(10px + 1vmin);
    border: none;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}

.Input-search-bar:focus {
    color: #383938;
    border: none;
    outline:#8ea98e;
}

.horizontal-top-line {
    width: 100%;
    border: 1px solid  #8ea98e;
}