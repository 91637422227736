.login {
    height: 50vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #e9e0d1;
    padding: 30px;
    border-radius: 5px;
  }
  .login__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid grey
  }
  .login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: #383938;
    border-radius: 5px;
    cursor: pointer;
  }
  .login__google {
    background-color: #4285f4;
  }
  .login div {
    margin-top: 7px;
  }