.About {
    background-color:  #f2ebde;
    max-width: 100%;
    font-size: calc(10px + 2vmin);
    color: #3e403e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6vh;
    margin-top: 4vh;
    border-radius: 8px;
    padding: 10px;
    padding-bottom: 20px; 
}

.Banner {
    font-weight: bold;
    font-size: calc(12px + 2vmin);
}

.Left-side {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(4px + 2vmin);
    text-align:left;
}

.Paragraphs {
    padding: 5px;
}