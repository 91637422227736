.Studies-background {
    background-color:  #e9e0d1;
    width: 94%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 5px;
    color: #383938;
    padding: 10px;
}

.Studies-container {
    background-color:  transparent;
    border-radius: 6px;
    width: 96%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 6px;
}

.Studies-notSignedIn-text {
    text-align: center;
    color: #2f302f;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: calc(4px + 2vmin);
    font-weight: 550;
    border-radius: 5px;
    margin: 10px;
    text-decoration: none;
}

.Studies-button-row {
    background-color:  transparent;
    width: 98%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    border-color: transparent;
    padding: 5px;
}

.Studies-selected-button-row {
    background-color:  #e9e0d1;
    width: 98%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-color: transparent;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.Studies-button {
    width: 95%;
    background-color:  transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    color: Black;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-color: transparent;
    overflow-wrap:normal;
}

.Studies-edit-button{
    background-color:  #383938;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: Black;
    border: none;
    border-radius: 100%;
}

.Studies-edit-button:hover {
    border-radius: 100%;
    background-color:  #505150;
}

.Studies-edit-icon {
    height: 18px;
    width: 18px;
}

.Studies-name-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
}

.Studies-name {
    background-color: transparent;
    font-size: calc(4px + 2vmin);
    border-radius: 5px;  
    color: #383938;
}

.Studies-selected-name {
    background-color: transparent;
    font-size: calc(5px + 2vmin);
    border-radius: 5px;
    color: black;
}

.Studies-remove-button {
    height: 20px;
    width: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
    padding: 4%;
    margin:1%;
}

.Studies-remove-icon {
    height: 20px;
    width: 20px;
    border-radius: 100%;
}

.Studies-remove-button:hover {
    background-color:  #ec988b;
}