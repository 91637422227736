.dashboard {
    height: 50vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dashboard__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #e9e0d1;
    padding: 30px;
    border-radius: 5px;
  }
  .dashboard__btn {
    padding: 10px;
    font-size: 18px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: black;
    border-radius: 5px;
  }
  .dashboard div {
    margin-top: 7px;
  }